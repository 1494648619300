import { createRouter, createWebHistory } from 'vue-router'
import AppLayout from '@/components/layout/AppLayout.vue'
import RequirementView from '@/views/requirements/RequirementView.vue'
import WorkCircleView from '@/views/workCircle/WorkCircleView.vue'

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: () => import('@/views/UserLogin.vue'),
    meta: { requiresGuest: true }
  },
  {
    path: '/register',
    name: 'UserRegister',
    component: () => import('@/views/UserRegister.vue'),
    meta: { requiresGuest: true }
  },
  {
    path: '/',
    component: AppLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/DashboardView.vue'),
        meta: { 
          title: '留言板',
          subtitle: '我提出的需求',
          pendingTitle: '待完成需求'
        }
      },
      {
        path: '/requirements',
        name: 'requirements',
        component: RequirementView,
        meta: { 
          title: '任务台',
          requiresAuth: true 
        }
      }, 
      {
        path: '/work-circle',
        name: 'WorkCircle',
        component: WorkCircleView,
        meta: {
          title: '工作圈',
          requiresAuth: true
        }
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/settings/SettingsView.vue'),
        meta: { 
          title: '系统设置',
          requiresAuth: true 
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // 设置页面标题
  document.title = to.meta.title || '我的工作 - 简单好用的工作管理系统'
  
  const token = localStorage.getItem('token');
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !token) {
    next('/login');
  } else {
    next();
  }
})

export default router