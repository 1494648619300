import { createStore } from 'vuex'
import { authApi } from '@/api/auth'
import { getUnreadCount, updateSubmitterReadStatus } from '@/api/requirements'
import request from '@/utils/request'

const store = createStore({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null,
    token: localStorage.getItem('token') || null,
    isAuthenticated: !!localStorage.getItem('token'),
    loading: false,
    error: null,
    unreadSubmittedCount: 0,
    unreadReviewCount: 0,  // 添加这一行
    requirements: [],
    currentRequirement: null
  },

  getters: {
    isAuthenticated: state => state.isAuthenticated,
    currentUser: state => state.user,
    getToken: state => state.token,
    isLoading: state => state.loading,
    getError: state => state.error
  },

  mutations: {
    SET_AUTH(state, { token, user }) {
      state.token = token
      state.user = user
      state.isAuthenticated = true
      // 保存到本地存储
      localStorage.setItem('token', token)
      localStorage.setItem('user', JSON.stringify(user))
    },

    CLEAR_AUTH(state) {
      state.token = null
      state.user = null
      state.isAuthenticated = false
      // 清除本地存储
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('rememberMe')
    },

    SET_LOADING(state, status) {
      state.loading = status
    },

    SET_ERROR(state, error) {
      state.error = error
    },

    SET_UNREAD_COUNT(state, count) {
      state.unreadSubmittedCount = parseInt(count) || 0
    },

    SET_UNREAD_REVIEW_COUNT(state, count) {
      state.unreadReviewCount = parseInt(count) || 0
    },

    MARK_REQUIREMENT_READ(state, requirementId) {
      const requirement = state.requirements.find(r => r.id === requirementId)
      if (requirement) {
        requirement.submitter_read = true
        if (state.unreadSubmittedCount > 0) {
          state.unreadSubmittedCount--
        }
      }
    },

    MARK_REVIEW_READ(state, requirementId) {
      const requirement = state.requirements.find(r => r.id === requirementId)
      if (requirement) {
        requirement.reviewer_read = true
        if (state.unreadReviewCount > 0) {
          state.unreadReviewCount--
        }
      }
    }
  },

  actions: {
    async login({ commit }, credentials) {
      try {
        commit('SET_LOADING', true)
        commit('SET_ERROR', null)
        
        const response = await authApi.login(
          credentials.username,
          credentials.password
        )
        
        if (response.token && response.user) {
          commit('SET_AUTH', {
            token: response.token,
            user: response.user
          })
          return true
        }
        throw new Error('登录响应格式错误')
      } catch (error) {
        commit('SET_ERROR', error.message || '登录失败')
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async logout({ commit }) {
      try {
        commit('SET_LOADING', true)
        await authApi.logout()
      } catch (error) {
        console.error('退出登录错误:', error)
      } finally {
        commit('CLEAR_AUTH')
        commit('SET_LOADING', false)
      }
    },

    // 检查并恢复认证状态
    initAuth({ commit }) {
      try {
        const token = localStorage.getItem('token')
        const user = localStorage.getItem('user')
        
        if (token && user) {
          commit('SET_AUTH', {
            token,
            user: JSON.parse(user)
          })
        } else {
          commit('CLEAR_AUTH')
        }
      } catch (error) {
        console.error('初始化认证状态错误:', error)
        commit('CLEAR_AUTH')
      }
    },

    async fetchUnreadCount({ commit }) {
      try {
        const { data } = await getUnreadCount()
        commit('SET_UNREAD_COUNT', data.count || 0)
      } catch (error) {
        console.error('获取未读数量失败:', error)
        commit('SET_UNREAD_COUNT', 0)
      }
    },
    
    async markRequirementAsRead({ commit }, requirementId) {
      try {
        await updateSubmitterReadStatus(requirementId)
        commit('MARK_REQUIREMENT_READ', requirementId)
      } catch (error) {
        console.error('标记已读失败:', error)
      }
    },

    async fetchUnreadReviewCount({ commit }) {
      try {
        console.log('开始获取未读审核数量')
        const response = await request.get('/api/requirements/unread-review-count')
        console.log('获取到的响应:', response)
        
        // 处理不同的响应格式
        let count = 0
        if (response.count !== undefined) {
          count = response.count
        } else if (response.data && response.data.count !== undefined) {
          count = response.data.count
        }
        
        console.log('处理后的未读数量:', count)
        commit('SET_UNREAD_REVIEW_COUNT', count)
      } catch (error) {
        console.error('获取未读审核数量失败:', error)
        commit('SET_UNREAD_REVIEW_COUNT', 0)
      }
    },
    
    async markReviewAsRead({ commit }, requirementId) {
      try {
        await request.put(`/api/requirements/${requirementId}/review-read`)
        commit('MARK_REVIEW_READ', requirementId)
      } catch (error) {
        console.error('标记审核已读失败:', error)
      }
    }
  }
})

// 初始化认证状态
store.dispatch('initAuth')

export default store
