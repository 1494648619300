<template>
  <div class="sticky-note">
    <div class="note-content">
      <div class="note-header">
        <el-avatar 
          :size="50" 
          :src="getAvatarUrl(contact)"
          class="avatar"
        >
          {{ getDefaultAvatar(contact) }}
        </el-avatar>
        <h3>{{ contact.username }}</h3>
      </div>
      <div class="note-body">
        <p v-if="contact.contact">
          <el-icon><Phone /></el-icon>
          {{ contact.contact }}
        </p>
        <p v-if="contact.notes" class="notes">
          <el-icon><Memo /></el-icon>
          {{ contact.notes }}
        </p>
      </div>
    </div>
    <div class="note-footer">
      <el-button-group>
        <el-button type="primary" link @click="$emit('edit', contact)">
          <el-icon><Edit /></el-icon>
        </el-button>
        <el-button type="danger" link @click="$emit('delete', contact)">
          <el-icon><Delete /></el-icon>
        </el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script setup>
import { 
  UserFilled, 
  Phone, 
  Memo,
  Edit, 
  Delete 
} from '@element-plus/icons-vue'
import { onMounted } from 'vue'

const props = defineProps({
  contact: {
    type: Object,
    required: true
  }
})

// 获取头像URL或默认值
const getAvatarUrl = (contact) => {
  if (!contact || !contact.avatar_url) return '';
    //return `http://192.168.1.40:8080${contact.avatar_url}`;
    return `https://api.gongzuo.me${contact.avatar_url}`;  // 线上环境
}

// 获取默认头像内容
const getDefaultAvatar = (contact) => {
  if (!contact || !contact.username) return '';
  return contact.username.charAt(0).toUpperCase();
}

onMounted(() => {
  console.log('ContactCard组件挂载，完整联系人数据:', props.contact);
})
</script>

<style scoped>
.sticky-note {
  background: #ffd977;
  padding: 15px;
  margin-bottom: 16px;
  border-radius: 2px;
  box-shadow: 
    2px 2px 4px rgba(0,0,0,0.1),
    0 0 0 1px rgba(0,0,0,0.08);
  position: relative;
  transition: all 0.3s ease;
  transform: rotate(-1deg);
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
  margin: 10px auto;
}

.sticky-note:nth-child(even) {
  transform: rotate(1deg);
  background: #fff4a5;
}

.sticky-note:hover {
  transform: scale(1.02) rotate(0);
  box-shadow: 
    2px 3px 8px rgba(0,0,0,0.15),
    0 0 0 1px rgba(0,0,0,0.08);
  z-index: 1;
}

.note-content {
  flex-grow: 1;
}

.note-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
}

.note-header h3 {
  margin: 0;
  font-size: 18px;
  color: #2c3e50;
  font-family: 'Comic Sans MS', cursive;
}

.avatar {
  background-color: #a7b6c5; /* 更换默认头像背景颜色 */
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px; /* 调整默认头像字体大小 */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* 添加轻微阴影 */
}

.note-body {
  font-family: 'Comic Sans MS', cursive;
  font-size: 13px;
  padding: 0 4px;
}

.note-body p {
  margin: 6px 0;
  display: flex;
  align-items: flex-start;
  gap: 6px;
  color: #34495e;
  line-height: 1.3;
  text-align: left;
  padding-left: 0;
}

.note-body .el-icon {
  margin-top: 3px;
  flex-shrink: 0;
  width: 16px;
}

.notes {
  font-size: 14px;
  color: #34495e;
  white-space: pre-wrap;
  text-align: left;
  display: flex;
  gap: 6px;
}

.note-footer {
  margin-top: 12px;
  padding-top: 8px;
  border-top: 1px dashed rgba(0,0,0,0.1);
  display: flex;
  justify-content: flex-end;
  opacity: 0.7;
}

.note-footer:hover {
  opacity: 1;
}

.el-icon {
  color: #34495e;
}

.el-col {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
</style>