<template>
    <div class="work-circle-container">
      <!-- 顶部操作栏 -->
      <div class="action-header">
        <div class="left">
          <el-input
            v-model="searchQuery"
            placeholder="搜索联系人"
            prefix-icon="Search"
            clearable
            @input="handleSearch"
          />
        </div>
        <el-button type="primary" @click="showAddContactDialog">
          <el-icon><Plus /></el-icon>添加联系人
        </el-button>
      </div>
  
      <!-- 联系人网格 -->
      <div class="contacts-container">
        <transition-group 
          v-if="filteredContacts.length"
          name="contact-list" 
          tag="div" 
          class="contacts-grid"
          appear
        >
          <el-col 
            v-for="contact in filteredContacts" 
            :key="contact.id" 
            :xs="24" 
            :sm="12" 
            :md="8" 
            :lg="6"
            class="contact-item"
          >
            <ContactCard
              :contact="contact"
              @edit="handleEdit"
              @delete="handleDelete"
            />
          </el-col>
        </transition-group>
        <el-empty 
          v-else 
          description="暂无联系人" 
          :image-size="200"
        >
          <el-button type="primary" @click="showAddContactDialog">
            添加第一个联系人
          </el-button>
        </el-empty>
      </div>
  
      <!-- 分页组件 -->
      <div class="pagination-container">
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :total="total"
          :page-sizes="[8, 16, 24, 32]"
          layout="prev, pager, next"
          background
          :hide-on-single-page="true"
          class="sticky-pagination"
        />
      </div>
  
      <!-- 添加/编辑联系人表单 -->
      <ContactForm
        v-model="dialogVisible"
        :type="dialogType"
        :contact="currentContact"
        @submit="handleSubmit"
      />
    </div>
  </template>
  
  <script setup>
  import { ref, computed, onMounted } from 'vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { Search, Plus } from '@element-plus/icons-vue'
  import request from '@/utils/request'
  import ContactCard from './components/ContactCard.vue'
  import ContactForm from './components/ContactForm.vue'
  
  const contacts = ref([])
  const searchQuery = ref('')
  const dialogVisible = ref(false)
  const dialogType = ref('add')
  const currentContact = ref(null)
  const currentPage = ref(1)
  const pageSize = ref(12)
  const total = ref(0)
  
  // 修改过滤逻辑，添加安全检查
  const filteredContacts = computed(() => {
    if (!contacts.value) return []
    
    const filtered = contacts.value.filter(contact => {
      if (!contact) return false
      return !searchQuery.value || 
        contact.username?.toLowerCase().includes(searchQuery.value.toLowerCase())
    })
    
    // 在前端进行分页
    const start = (currentPage.value - 1) * pageSize.value
    const end = start + pageSize.value
    return filtered.slice(start, end)
  })
  
  // 获取联系人列表
  const fetchContacts = async () => {
    try {
      const response = await request.get('/api/contacts')
      console.log('联系人数据:', response)
      
      // 直接使用返回的数组
      if (Array.isArray(response)) {
        contacts.value = response
        total.value = response.length
      } else if (Array.isArray(response.data)) {
        contacts.value = response.data
        total.value = response.data.length
      } else {
        contacts.value = []
        total.value = 0
      }
    } catch (error) {
      console.error('获取联系人列表失败:', error)
      ElMessage.error('获取联系人列表失败')
      contacts.value = []
      total.value = 0
    }
  }
  
  // 显示添加对话框
  const showAddContactDialog = () => {
    dialogType.value = 'add'
    currentContact.value = null
    dialogVisible.value = true
  }
  
  // 处理编辑
  const handleEdit = (contact) => {
    dialogType.value = 'edit'
    currentContact.value = { ...contact }
    dialogVisible.value = true
  }
  
  // 处理删除
  const handleDelete = async (contact) => {
    try {
      await ElMessageBox.confirm('确定要删除该联系人吗？', '提示', {
        type: 'warning'
      })
      await request.delete(`/api/contacts/${contact.id}`)
      ElMessage.success('删除成功')
      await fetchContacts()
    } catch (error) {
      if (error !== 'cancel') {
        ElMessage.error('删除失败')
      }
    }
  }
  
  // 处理表单提交
  const handleSubmit = async (formData) => {
    try {
      if (dialogType.value === 'add') {
        await request.post('/api/contacts', formData)
        ElMessage.success('添加成功')
      } else {
        await request.put(`/api/contacts/${currentContact.value.id}`, formData)
        ElMessage.success('更新成功')
      }
      await fetchContacts()
    } catch (error) {
      ElMessage.error(dialogType.value === 'add' ? '添加失败' : '更新失败')
    }
  }
  
  // 搜索处理
  const handleSearch = () => {
    // 实时搜索，不需要额外处理，computed 会自动处理过滤
  }
  
  onMounted(() => {
    fetchContacts()
  })
  </script>
  
  <style scoped>
  .work-circle-container {
    padding: 20px;
  }
  
  .action-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .action-header .left {
    display: flex;
    gap: 16px;
  }
  
  .contacts-container {
    min-height: 400px; /* 确保空状态时有足够的显示空间 */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contacts-grid {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 60px; /*便签间距*/
    padding: 10px;
    min-height: 200px;
  }
  
  /* 自定义空状态样式 */
  :deep(.el-empty) {
    padding: 40px;
  }
  
  :deep(.el-empty__description) {
    margin-top: 20px;
    font-size: 16px;
    color: #909399;
  }
  
  :deep(.el-empty__bottom) {
    margin-top: 20px;
  }
  
  /* 响应式布局 */
  @media (max-width: 768px) {
    .contacts-grid {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      gap: 10px;
    }
  }
  
  .pagination-container {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    padding: 15px 0;
  }
  
  .sticky-pagination {
    padding: 12px 25px;
    background: #ffd977;
    border-radius: 8px;
    box-shadow: 
      0 4px 8px rgba(0,0,0,0.1),
      0 0 0 1px rgba(0,0,0,0.05);
    transform: rotate(-1deg);
  }
  
  :deep(.el-pagination.is-background) {
    .btn-prev,
    .btn-next,
    .el-pager li {
      background-color: transparent;
      color: #606266;
      border: none;
      margin: 0 5px;
      min-width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      transition: all 0.3s ease;
      
      &:hover {
        color: #409eff;
        background-color: rgba(255,255,255,0.8);
        transform: scale(1.1);
      }
      
      &.is-active {
        background-color: #fff;
        color: #409eff;
        font-weight: bold;
        box-shadow: 0 2px 6px rgba(0,0,0,0.15);
        transform: scale(1.1);
      }
    }
    
    .btn-prev,
    .btn-next {
      &:hover {
        background-color: rgba(255,255,255,0.8);
      }
    }
  }
  
  /* 添加悬停效果 */
  .sticky-pagination:hover {
    transform: rotate(0deg) translateY(-2px);
    box-shadow: 
      0 6px 12px rgba(0,0,0,0.12),
      0 0 0 1px rgba(0,0,0,0.05);
    transition: all 0.3s ease;
  }
  
  /* 修改过渡动画 */
  .contact-list-move,
  .contact-list-enter-active,
  .contact-list-leave-active {
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .contact-list-enter-from,
  .contact-list-appear-from {
    opacity: 0;
    transform: translateY(30px) rotate(8deg);
  }
  
  .contact-list-leave-to {
    opacity: 0;
    transform: translateY(-30px) rotate(-8deg);
  }
  
  .contact-list-leave-active {
    position: absolute;
  }
  
  /* 确保每个卡片都有正确的定位 */
  .contact-item {
    backface-visibility: hidden;
    will-change: transform, opacity;
  }
  </style>