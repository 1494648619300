console.log('main.js 初始化开始')

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from 'axios'

console.log('依赖导入完成')

const app = createApp(App)
console.log('Vue 应用创建完成')

app.use(ElementPlus, {
  locale: zhCn,
  config: {
    silent: true
  }
})
console.log('ElementPlus 插件加载完成')

app.use(store)
console.log('Vuex store 加载完成')

app.use(router)
console.log('Vue Router 加载完成')

// 注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
console.log('ElementPlus Icons 加载完成')

// 全局配置 axios 错误处理
axios.defaults.showError = false

app.mount('#app')
console.log('应用挂载完成')

console.log('main.js 初始化完成')