<template>
    <el-dialog
      v-model="dialogVisible"
      :title="type === 'add' ? '添加联系人' : '编辑联系人'"
      width="500px"
    >
      <el-form
        ref="formRef"
        :model="contactForm"
        :rules="contactRules"
        label-width="80px"
      >
        <el-form-item 
          label="用户名" 
          prop="username"
          :validateStatus="usernameValidateStatus"
        >
          <el-input 
            v-model="contactForm.username" 
            placeholder="请输入用户名"
            @blur="checkUsername"
          />
          <div v-if="usernameMessage" :class="['username-message', usernameValidateStatus]">
            {{ usernameMessage }}
          </div>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact">
          <el-input
            v-model="contactForm.contact"
            placeholder="请输入联系方式"
          />
        </el-form-item>
        <el-form-item label="备注" prop="notes">
          <el-input
            v-model="contactForm.notes"
            type="textarea"
            :rows="3"
            placeholder="添加备注信息"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleCancel">取消</el-button>
        <el-button 
          type="primary" 
          @click="handleSubmit"
          :disabled="!isUsernameValid"
        >确定</el-button>
      </template>
    </el-dialog>
  </template>

  <script setup>
  import { ref, watch } from 'vue'
  import { ElMessage } from 'element-plus'
  import request from '@/utils/request'

  const props = defineProps({
    modelValue: Boolean,
    type: {
      type: String,
      default: 'add'
    },
    contact: {
      type: Object,
      default: () => ({})
    }
  })
  
  const emit = defineEmits(['update:modelValue', 'submit'])
  
  const dialogVisible = ref(props.modelValue)
  const formRef = ref(null)
  
  const contactForm = ref({
    username: '',
    contact: '',
    notes: ''
  })
  
  const contactRules = {
    username: [
      { required: true, message: '请输入用户名', trigger: 'blur' }
    ]
  }
  
  const usernameValidateStatus = ref('')
  const usernameMessage = ref('')
  const isUsernameValid = ref(false)
  
  // 检查用户名是否已存在
  const checkUsername = async () => {
    if (!contactForm.value.username) {
      usernameMessage.value = '请输入用户名'
      usernameValidateStatus.value = 'error'
      isUsernameValid.value = false
      return
    }
    
    try {
      console.log('开始验证用户名:', contactForm.value.username)
      const response = await request.get(`/api/contacts/validate-username/${contactForm.value.username}`)
      console.log('验证响应:', response)
      
      if (response.exists && props.type === 'add') {
        usernameMessage.value = '恭喜，该用户可以添加'
        usernameValidateStatus.value = 'error'
        isUsernameValid.value = true
        // ElMessage.warning('恭喜，该用户可以添加')
      } else {
        usernameMessage.value = '请他（她）先去注册，再添加'
        usernameValidateStatus.value = 'success'
        isUsernameValid.value = false
        // ElMessage.success('请TA先去注册，再添加')
      }
    } catch (error) {
      console.error('网络问题，请稍后再试:', error)
      usernameMessage.value = '网络问题，请稍后再试'
      usernameValidateStatus.value = 'error'
      isUsernameValid.value = false
      // ElMessage.error('网络问题，请稍后再试')
    }
  }
  
  watch(() => props.modelValue, (val) => {
    dialogVisible.value = val
  })
  
  watch(() => dialogVisible.value, (val) => {
    emit('update:modelValue', val)
  })
  
  watch(() => props.contact, (val) => {
    if (val) {
      contactForm.value = { ...val }
    }
  }, { immediate: true })
  
  // 添加一个 watch 来处理编辑模式
  watch(
    () => [props.type, props.contact],
    ([type, contact]) => {
      if (type === 'edit' && contact) {
        // 编辑模式下，自动设置用户名为有效
        isUsernameValid.value = true
        usernameValidateStatus.value = 'success'
        usernameMessage.value = ''
      } else {
        // 添加模式下，重置状态
        isUsernameValid.value = false
        usernameValidateStatus.value = ''
        usernameMessage.value = ''
      }
    },
    { immediate: true }
  )
  
  const handleCancel = () => {
    dialogVisible.value = false
    formRef.value?.resetFields()
  }
  
  const handleSubmit = async () => {
    if (!formRef.value) return
    
    await formRef.value.validate(async (valid) => {
      if (valid) {
        if (!contactForm.value.username) {
          ElMessage.warning('请输入用户名')
          return
        }
        emit('submit', contactForm.value)
        handleCancel()
      }
    })
  }
  </script>

  <style scoped>
  .username-message {
    font-size: 12px;
    margin-top: 5px;
  }

  .username-message.error {
    color: #f56c6c;
  }

  .username-message.success {
    color: #67c23a;
  }
  </style>