<template>
    <div class="app-container">
      <AppHeader 
        v-model:is-collapse="isCollapse"
        @logout="handleLogout"
      />
      <div class="main-container">
        <AppSidebar :is-collapse="isCollapse" />
        <div class="content">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { ElMessage } from 'element-plus'
  import AppHeader from './AppHeader.vue'
  import AppSidebar from './AppSidebar.vue'
  import { authApi } from '@/api/auth'
  
  const router = useRouter()
  const isCollapse = ref(false)
  
  const handleLogout = async () => {
    try {
      await authApi.logout()
      ElMessage.success('退出成功')
      router.push('/login')
    } catch (error) {
      ElMessage.error('退出失败')
    }
  }
  </script>
  
  <style scoped lang="scss">
  .app-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .main-container {
    flex: 1;
    display: flex;
    overflow: hidden;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: var(--el-bg-color-page);
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  </style>