import request from '@/utils/request'

// 获取需求列表
export function getRequirements(params) {
  return request({
    url: '/requirements',
    method: 'get',
    params
  })
}

// 创建需求
export function createRequirement(data) {
  return request({
    url: '/requirements',
    method: 'post',
    data
  })
}

// 获取需求详情
export function getRequirementDetail(id) {
  return request({
    url: `/requirements/${id}`,
    method: 'get'
  })
}

// 更新需求状态和分类（合并了updateRequirementStatus和updateRequirementCategory）
export function updateRequirementCategory(id, category) {
  console.log('=== API调用：更新需求状态和分类 ===')
  console.log('参数:', { id, category })
  return request({
    url: `/api/requirements/${id}/status`,
    method: 'put',
    data: {
      status: 'accepted',  // 设置状态为已接受
      category  // 设置分类
    }
  })
}

// 添加里程碑
export function addMilestone(requirementId, data) {
  return request({
    url: `/requirements/${requirementId}/milestones`,
    method: 'post',
    data
  })
}

// 获取日历视图的需求统计
export function getCalendarRequirements(params) {
  return request({
    url: '/requirements/calendar',
    method: 'get',
    params
  })
}

// 更新提交者阅读状态
export function updateSubmitterReadStatus(id) {
  return request({
    url: `/api/requirements/${id}/submitter-read-status`,
    method: 'put'
  })
}

// 获取未读数量
export function getUnreadCount() {
  return request({
    url: '/api/requirements/unread-count',
    method: 'get'
  })
}

// 删除需求（支持单个和批量）
export function deleteRequirements(ids) {
  console.log('=== API调用：删除需求 ===')
  console.log('请求参数:', { ids })
  return request({
    url: '/api/requirements',
    method: 'delete',
    data: { ids }
  })
}

// 添加缺失的函数
export const batchDeleteRequirements = (ids) => {
  return request.delete('/api/requirements/batch', { data: { ids } })
}

export const updateRequirementStatus = (id, status) => {
  return request.put(`/api/requirements/${id}/status`, { status })
}

export const getCategories = () => {
  return request.get('/api/categories')
}

// 获取未读审核数量
export const getUnreadReviewCount = () => {
  return request.get('/api/requirements/unread-review-count')
}

// 更新审核已读状态
export const updateReviewerReadStatus = (requirementId) => {
  return request.put(`/api/requirements/${requirementId}/review-read`)
}