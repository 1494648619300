/**
 * 格式化日期时间为 YYYY-MM-DD HH:mm 格式
 * @param {Date|string} date 日期对象或日期字符串
 * @returns {string} 格式化后的日期时间字符串
 */
export const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
}

/**
 * 格式化日期为 YYYY-MM-DD 格式
 * @param {Date|string} date 日期对象或日期字符串
 * @returns {string} 格式化后的日期字符串
 */
export const formatDateOnly = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
}

/**
 * 格式化日期时间为相对时间（如：刚刚、5分钟前、1小时前等）
 * @param {Date|string} date 日期对象或日期字符串
 * @returns {string} 相对时间字符串
 */
export function formatRelativeTime(date) {
    if (!date) return '';
    
    if (typeof date === 'string') {
        date = new Date(date);
    }
    
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    
    if (minutes < 1) return '刚刚';
    if (minutes < 60) return `${minutes}分钟前`;
    if (hours < 24) return `${hours}小时前`;
    if (days < 30) return `${days}天前`;
    
    return formatDate(date);
}

/**
 * 格式化日期时间为完整格式 YYYY-MM-DD HH:mm:ss
 * @param {Date|string} date 日期对象或日期字符串
 * @returns {string} 格式化后的完整日期时间字符串
 */
export function formatFullDateTime(date) {
    if (!date) return '';
    
    if (typeof date === 'string') {
        date = new Date(date);
    }
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * 判断日期是否为今天
 * @param {Date|string} date 日期对象或日期字符串
 * @returns {boolean} 是否为今天
 */
export function isToday(date) {
    if (!date) return false;
    
    if (typeof date === 'string') {
        date = new Date(date);
    }
    
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
}

// 如果需要时间，可以添加这个函数
export const formatDateTime = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });
}