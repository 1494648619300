import request from '../utils/request'

export const authApi = {
    async register(username, email, password) {
        console.log('发送注册请求到:', process.env.VUE_APP_API_URL + '/api/auth/register');  // 添加日志
        const response = await request.post('/api/auth/register', {
            username,
            email,
            password
        })
        return response.data
    },

    async login(username, password) {
        const response = await request.post('/api/auth/login', {
            username,
            password
        })
        return response
    },

    async changePassword(oldPassword, newPassword) {
        const response = await request.put('/api/auth/user/password', {
            oldPassword,
            newPassword
        })
        return response.data
    },
    async logout() {
      const response = await request.post('/api/auth/logout')  // 修改为与其他接口一致的路径
      return response.data  // 修改这里，保持与其他方法一致的返回格式
    }
}

// // 项目相关接口
// export const createProject = (data) => {
//     return request.post('/api/projects', data)  // 确保路径是 /api/projects
// }

// export const getProjects = () => {
//     return request.get('/api/projects')  // 确保路径是 /api/projects
// }

// // 用户相关接口
// export const getUsers = () => {
//     return request.get('/api/users')
// }

// // 需求相关接口
// export const getRequirements = () => {
//     return request.get('/api/requirements')
// }

// export const createRequirement = (data) => {
//     return request.post('/api/requirements', data)
// }