<template>
    <el-menu
      :default-active="activeMenu"
      class="sidebar-menu"
      :collapse="isCollapse"
      @select="handleSelect"
    >
      <div class="logo-container">
        <img v-if="!isCollapse" src="@/assets/logo.png" class="logo" alt="logo">
        <img v-else src="@/assets/logo-small.png" class="logo-small" alt="logo">
      </div>
  
      <el-menu-item index="/dashboard">
        <el-icon><HomeFilled /></el-icon>
        <template #title>留言板</template>
      </el-menu-item>

      <el-menu-item index="/requirements">
        <el-icon><Document /></el-icon>
        <span>任务台</span>
      </el-menu-item>

      <el-menu-item index="/work-circle">
        <el-icon><UserFilled /></el-icon>
        <template #title>工作圈</template>
      </el-menu-item>
  
      <el-menu-item index="/settings">
        <el-icon><Setting /></el-icon>
        <template #title>系统设置</template>
      </el-menu-item>
    </el-menu>
  </template>
  
  <script setup>
  import { computed } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { 
    HomeFilled, 
    Calendar, 
    PieChart, 
    UserFilled, 
    Setting,
    Document
  } from '@element-plus/icons-vue'
  import { Monitor, Folder } from '@element-plus/icons-vue'  // 添加 Folder 图标
  
  // 直接使用 defineProps，不要赋值给变量
  defineProps({
    isCollapse: {
      type: Boolean,
      required: true
    }
  })
  
  const router = useRouter()
  const route = useRoute()
  
  const activeMenu = computed(() => route.path)
  
  const handleSelect = (index) => {
    router.push(index)
  }
  </script>
  
  <style scoped lang="scss">
  .sidebar-menu {
    height: 100%;
    border-right: 1px solid var(--el-border-color-light);
  
    &:not(.el-menu--collapse) {
      width: 240px;
    }
  }
  
  .logo-container {
    height: 60px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--el-border-color-light);
  
    .logo {
      height: 40px;
      object-fit: contain;
    }
  
    .logo-small {
      height: 32px;
      width: 32px;
      object-fit: contain;
    }
  }
  
  :deep(.el-menu-item), :deep(.el-sub-menu__title) {
    .el-icon {
      font-size: 18px;
    }
  }
  </style>