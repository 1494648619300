import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router'

// 创建 axios 实例
const service = axios.create({
  // 开发环境用 process.env
  // 生产环境用 硬编码
  baseURL: process.env.VUE_APP_API_URL,
  //baseURL: 'https://api.gongzuo.me',
  timeout: 15000, // 请求超时时间
  //withCredentials: true  // 允许跨域请求携带凭证
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 添加详细的请求日志
    console.group('API Request')
    console.log('完整URL:', config.baseURL + config.url)
    console.log('请求方法:', config.method)
    console.log('请求参数:', config.params)
    console.log('请求数据:', config.data)
    console.log('请求头:', config.headers)
    console.groupEnd()

    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  error => {
    console.error('请求错误:', error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 添加详细的响应日志
    console.group('API Response')
    console.log('请求URL:', response.config.url)
    console.log('响应状态:', response.status)
    console.log('响应数据:', response.data)
    console.groupEnd()

    const res = response.data
    // 2xx 都是成功的状态码
    if (response.status >= 200 && response.status < 300) {
      return res
    }
    ElMessage({
      message: res.message || '错误',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(new Error(res.message || '错误'))
  },
  error => {
    // 添加详细的错误日志
    console.group('API Error')
    console.error('请求URL:', error.config?.url)
    console.error('错误状态:', error.response?.status)
    console.error('错误信息:', error.message)
    console.error('错误响应:', error.response?.data)
    console.groupEnd()

    // 直接返回 reject，不显示错误消息
    return Promise.reject(error)
  }
)

export default service