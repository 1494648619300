<template>
  <div class="requirement-view">
    <!-- 顶部标签页 -->
    <el-tabs v-model="activeTab" @tab-click="handleTabChange" class="custom-tabs">
      <el-tab-pane label="我收到的需求" name="received" />
      <el-tab-pane label="我提出的需求" name="submitted" />
      <el-tab-pane name="reviewing">
        <template #label>
          <div class="review-tab">
            我审核的需求
            <el-badge
              v-if="unreadReviewCount > 0"
              :value="unreadReviewCount"
              class="review-badge"
              type="danger"
            />
          </div>
        </template>
      </el-tab-pane>
    </el-tabs>

    <!-- 操作栏 -->
    <div class="operation-bar">
      <el-row :gutter="20" align="middle">
        <el-col :span="16">
          <!-- 筛选条件 -->
          <el-form :inline="true" class="search-form">
            <el-form-item label="状态">
              <el-select v-model="filters.status" placeholder="选择状态" clearable class="status-select">
                <template v-if="activeTab === 'received'">
                  <el-option label="待处理" value="pending" />
                  <el-option label="处理中" value="accepted" />
                  <el-option label="中止任务" value="communicating" />
                  <el-option label="转审核" value="reviewing" />
                  <el-option label="已完成" value="completed" />
                </template>
                <template v-else-if="activeTab === 'submitted'">
                  <el-option label="待处理" value="pending" />
                  <el-option label="处理中" value="accepted" />
                  <el-option label="中止任务" value="communicating" />
                  <el-option label="审核中" value="reviewing" />
                  <el-option label="审核通过" value="review_approved" />
                  <el-option label="审核拒绝" value="review_rejected" />
                  <el-option label="待验收" value="completed" />
                  <el-option label="已完成" value="accepted_completion" />
                  <el-option label="已拒绝" value="rejected_completion" />
                </template>
                <template v-else-if="activeTab === 'reviewing'">
                  <el-option label="待审核" value="reviewing" />
                  <el-option label="已通过" value="review_approved" />
                  <el-option label="已拒绝" value="review_rejected" />
                </template>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="loadRequirements">查询</el-button>
              <el-button @click="resetFilters">重置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" class="text-right">
          <!-- 根据不同标签页显示不同的按钮 -->
          <template v-if="activeTab === 'received'">
            <el-button @click="showCategoryManageDialog">
              <el-icon><Setting /></el-icon>归类管理
            </el-button>
            <!-- <el-button 
              type="primary"
              :disabled="!selectedRows.length"
              @click="handleClassify"
            >
              归类
            </el-button> -->
          </template>
          <template v-if="activeTab === 'submitted'">
            <el-button type="primary" @click="showCreateDialog">
              <el-icon><Plus /></el-icon>提出需求
            </el-button>
          </template>
          <!-- 添加批量删除按钮 -->
          <template v-if="activeTab === 'received' || activeTab === 'submitted'">
            <el-button 
              type="danger"
              :disabled="!selectedRows.length"
              @click="handleDelete"
            >
              删除选中项
            </el-button>
          </template>
        </el-col>
      </el-row>
    </div>

    <!-- 需求列表 -->
    <el-table
      v-loading="loading"
      :data="currentPageData"
      style="width: 100%"
      :header-cell-style="{ background: '#f5f7fa' }"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
      border
    >
      <!-- 添加多选列 -->
      <el-table-column
        v-if="activeTab === 'received' || activeTab === 'submitted'"
        type="selection"
        width="55"
      />
      
      <el-table-column prop="title" label="需求标题" min-width="280">
        <template #default="{ row }">
          <div class="requirement-title-wrapper">
            <el-link type="primary" @click="showDetailDialog(row)" class="requirement-title">
              {{ row.title }}
            </el-link>
            <template v-if="activeTab === 'submitted' && !row.submitter_read">
              <span class="status-change-indicator">
                <el-icon><Bell /></el-icon>
                有新更新
              </span>
            </template>
          </div>
        </template>
      </el-table-column>
      
      <!-- 根据不同Tab显示不同列 -->
      <template v-if="activeTab === 'received'">
        <el-table-column prop="submitter_name" label="提出人" width="120" align="center" />
        <el-table-column prop="category" label="归类" width="120" align="center" />
      </template>
      
      <el-table-column prop="expected_completion_date" label="期望完成时间" width="160" align="center">
        <template #default="{ row }">
          {{ formatDateOnly(row.expected_completion_date) }}
        </template>
      </el-table-column>
      
      <el-table-column prop="status" label="状态" width="120" align="center">
        <template #default="{ row }">
          <el-tag :type="getStatusType(row.status)" effect="plain">
            {{ getStatusLabel(row.status) }}
          </el-tag>
        </template>
      </el-table-column>
      
      <el-table-column prop="created_at" label="创建时间" width="160" align="center">
        <template #default="{ row }">
          {{ formatDate(row.created_at) }}
        </template>
      </el-table-column>

      <!-- 添加里程碑显示列 -->
      <el-table-column label="最新进展" min-width="200" v-if="activeTab === 'submitted'">
        <template #default="{ row }">
          <div v-if="row.latest_milestone_desc" class="latest-milestone-info">
            <div class="milestone-header">
              <span class="milestone-desc">{{ row.latest_milestone_desc }}</span>
            </div>
            <div class="milestone-progress-row">
              <span class="progress-label">完成进度</span>
              <div class="progress-wrapper">
                <el-progress 
                  :percentage="parseFloat(row.latest_milestone_progress) || 0" 
                  :format="(val) => `${val}%`"
                  :stroke-width="8"
                  class="compact-progress"
                />
              </div>
            </div>
          </div>
          <span v-else class="no-milestone">暂无进展</span>
        </template>
      </el-table-column>

      <!-- 修改时间进度列 -->
      <el-table-column label="时间条" width="200" align="center">
        <template #default="{ row }">
          <div class="time-progress-cell">
            <el-progress 
              :percentage="getTimeBasedProgress(row)"
              :format="(val) => `${val}%`"
              :stroke-width="10"
            />
          </div>
        </template>
      </el-table-column>

      <!-- 操作列 -->
      <el-table-column label="操作" width="240" fixed="right" align="center">
        <template #default="{ row }">
          <!-- 处理人的操作按钮 -->
          <template v-if="activeTab === 'received'">
            <!-- 待处理状态的操作 -->
            <el-button-group v-if="row.status === 'pending'">
              <el-button type="primary" link @click="handleAccept(row)">接受</el-button>
              <el-button type="warning" link @click="handleReview(row)">转审核</el-button>
              <el-button link @click="handleCommunicate(row)">我拒绝</el-button>
            </el-button-group>
            
            <!-- 审核通过状态的操作 -->
            <el-button-group v-if="row.status === 'review_approved'">
              <el-button type="primary" link @click="handleAccept(row)">接受</el-button>
              <el-button link @click="handleCommunicate(row)">我拒绝</el-button>
            </el-button-group>
            
            <!-- 处理中状态的操作 -->
            <template v-if="row.status === 'accepted'">
              <el-button type="primary" link @click="showMilestoneDialog(row)">
                更新进度
              </el-button>
              <el-button type="success" link @click="markAsCompleted(row)">
                标记完成
              </el-button>
            </template>
          </template>
          
          <!-- 提出人的操作按钮 -->
          <template v-if="activeTab === 'submitted'">
            <el-button-group v-if="row.status === 'completed'">
              <el-button 
                type="success" 
                link 
                @click="handleAcceptCompletion(row)"
              >
                通过验收
              </el-button>
              <el-button 
                type="danger" 
                link 
                @click="handleRejectCompletion(row)"
              >
                拒绝验收
              </el-button>
            </el-button-group>
          </template>
          
          <!-- 审核人的操作按钮 -->
          <template v-if="activeTab === 'reviewing' && row.status === 'reviewing'">
            <el-button-group>
              <el-button type="success" link @click="handleApproveReview(row)">通过</el-button>
              <el-button type="danger" link @click="showRejectReviewDialog(row)">拒绝</el-button>
            </el-button-group>
          </template>
          
          <!-- 详情按钮 -->
          <el-button type="primary" link @click="showDetailDialog(row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加分页器 -->
    <div class="pagination-container">
      <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[10, 20, 30, 50]"
        :total="requirements.length"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 创建需求对话框 -->
    <el-dialog
      v-model="createDialogVisible"
      title="提出需求"
      width="600px"
    >
      <el-form
        ref="createFormRef"
        :model="requirementForm"
        :rules="createRules"
        label-width="120px"
      >
        <el-form-item label="需求标题" prop="title">
          <el-input v-model="requirementForm.title" placeholder="请输入需求标题" />
        </el-form-item>
        <el-form-item label="需求内容" prop="content">
          <el-input
            v-model="requirementForm.content"
            type="textarea"
            :rows="6"
            :maxlength="1000"
            show-word-limit
            placeholder="请详细描述需求"
            resize="vertical"
            :autosize="{ minRows: 6, maxRows: 12 }"
          />
        </el-form-item>
        <el-form-item label="联系方式" prop="contact">
          <el-input 
            v-model="requirementForm.contact" 
            placeholder="请输入您的联系方式（手机号/微信/邮箱等）"
          />
        </el-form-item>
        <el-form-item label="期望完成时间" prop="deadline">
          <el-date-picker
            v-model="requirementForm.deadline"
            type="date"
            placeholder="选择期望完成时间"
            :disabledDate="disabledDate"
          />
        </el-form-item>
        <el-form-item label="接收人" required>
          <el-input 
            v-model="requirementForm.receiver"
            placeholder="请输入接收人用户名"
            @blur="validateReceiver(requirementForm.receiver)"
          />
          <!-- 添加验证消息显示 -->
          <div 
            v-if="receiverValidateMessage" 
            :class="['validate-message', receiverValidateType]"
          >
            {{ receiverValidateMessage }}
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="createDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitRequirement">提交</el-button>
      </template>
    </el-dialog>

    <!-- 需求详情对话框 -->
    <el-dialog
      v-model="detailDialogVisible"
      title="需求详情"
      width="800px"
      class="requirement-detail-dialog"
    >
      <template v-if="currentRequirement">
        <!-- 顶部状态卡片 -->
        <div class="detail-status-card" :class="currentRequirement.status">
          <div class="status-main">
            <div class="status-info">
              <el-icon class="status-icon">
                <CircleCheckFilled v-if="currentRequirement.status === 'completed' || currentRequirement.status === 'accepted_completion'" />
                <Loading v-else-if="currentRequirement.status === 'reviewing'" />
                <InfoFilled v-else />
              </el-icon>
              <div class="status-text">
                <span class="label">当前状态</span>
                <div class="status-with-reason">
                  <el-tag :type="getStatusType(currentRequirement.status)">
                    {{ getStatusLabel(currentRequirement.status) }}
                  </el-tag>
                </div>
              </div>
            </div>
            
            <div class="status-timeline">
              <div class="timeline-item">
                <div class="timeline-label">期望完成</div>
                <div class="timeline-value">
                  {{ formatDate(currentRequirement.expected_completion_date) }}
                  <span class="remaining-days-tag">
                    {{ getRemainingDays(currentRequirement.expected_completion_date) }}
                  </span>
                </div>
              </div>
              <el-divider direction="vertical" />
              <div class="timeline-item">
                <div class="timeline-label">创建时间</div>
                <div class="timeline-value">
                  {{ formatDate(currentRequirement.created_at) }}
                </div>
              </div>
              <el-divider direction="vertical" />
              <div class="timeline-item">
                <div class="timeline-label">最新进展</div>
                <div class="timeline-value">
                  {{ getLatestMilestoneDate(currentRequirement) }}
                </div>
              </div>
            </div>
          </div>
          
          <div class="status-extra">
            <div class="extra-item">
              <span class="extra-label">时间进度</span>
              <span class="extra-value">
                <el-progress 
                  :percentage="getTimeBasedProgress(currentRequirement)" 
                  :format="(val) => `${val}%`"
                  :stroke-width="8"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- 主要信息卡片 -->
        <div class="detail-main-card">
          <h2 class="detail-title text-left">{{ currentRequirement.title }}</h2>
          <div class="detail-meta">
            <span class="meta-item">
              <el-icon><User /></el-icon>
              提出人：{{ currentRequirement.submitter_name }}
              <el-divider direction="vertical" />
              联系方式：{{ currentRequirement.contact || '暂无' }}
            </span>
            <span class="meta-item" v-if="currentRequirement.category">
              <el-icon><Collection /></el-icon>
              归类：{{ currentRequirement.category }}
            </span>
          </div>
          
          <!-- 需求内容区 -->
          <div class="detail-content text-left">
            <h3 class="section-title">需求描述</h3>
            <div class="content-box">
              <pre class="requirement-description">{{ currentRequirement.description }}</pre>
            </div>
          </div>

          <!-- 审核信息区 -->
          <template v-if="currentRequirement.reviewer_name || currentRequirement.review_reason">
            <div class="detail-review">
              <h3 class="section-title">审核信息</h3>
              <div class="review-info">
                <div class="review-item" v-if="currentRequirement.reviewer_name">
                  <span class="label">审核人：</span>
                  <span class="value">{{ currentRequirement.reviewer_name }}</span>
                </div>
                <div class="review-item" v-if="currentRequirement.review_reason">
                  <span class="label">审核意见：</span>
                  <div class="value review-reason">
                    {{ currentRequirement.review_reason }}
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- 里程碑时间线 -->
          <div class="milestone-section">
            <h3 class="section-title">里程碑进展</h3>
            <el-timeline v-if="currentRequirement.milestones?.length">
              <el-timeline-item
                v-for="milestone in sortedMilestones"
                :key="milestone.id"
                :timestamp="formatDate(milestone.milestone_date)"
                :type="getMilestoneType(milestone.progress)"
              >
                <div class="milestone-content">
                  <div class="milestone-header">
                    <div class="milestone-description">{{ milestone.description }}</div>
                  </div>
                  <div class="milestone-progress-row">
                    <span class="progress-label">完成进度</span>
                    <div class="progress-wrapper">
                      <el-progress 
                        :percentage="parseFloat(milestone.progress) || 0"
                        :status="getMilestoneStatus(milestone.progress)"
                        :stroke-width="8"
                        class="compact-progress"
                      />
                    </div>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>
            <div v-else class="no-milestone">
              暂无里程碑记录
            </div>
          </div>
        </div>
      </template>
    </el-dialog>

    <!-- 接受需求对话框 -->
    <el-dialog
      v-model="acceptDialogVisible"
      title="接受需求"
      width="500px"
    >
      <el-form
        ref="acceptFormRef"
        :model="acceptForm"
        :rules="acceptRules"
        label-width="100px"
      >
        <el-form-item label="需求归类" prop="category">
          <el-input
            v-model="acceptForm.category"
            placeholder="请输入需求归类"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="acceptDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitAccept">确认接受</el-button>
      </template>
    </el-dialog>

    <!-- 里程碑对话框 -->
    <el-dialog
      v-model="milestoneDialogVisible"
      title="更新里程碑"
      width="500px"
    >
      <el-form
        ref="milestoneFormRef"
        :model="milestoneForm"
        :rules="milestoneRules"
        label-width="100px"
      >
        <el-form-item label="里程碑说明" prop="description">
          <el-input
            v-model="milestoneForm.description"
            type="textarea"
            :rows="3"
            placeholder="请输入里程碑说明"
          />
        </el-form-item>
        <el-form-item label="任务进度" prop="progress">
          <el-input
            v-model="milestoneForm.progress"
            placeholder="请输入当前任务进度，0~100之间的数字"
          />
        </el-form-item>
        <el-form-item label="时间" prop="milestone_date">
          <el-date-picker
            v-model="milestoneForm.milestone_date"
            type="datetime"
            placeholder="选择日期时间"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="milestoneDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitMilestone">提交</el-button>
      </template>
    </el-dialog>

    <!-- 拒绝审核对话框 -->
    <el-dialog
      v-model="rejectReviewDialogVisible"
      title="拒绝审核"
      width="500px"
    >
      <el-form
        ref="rejectReviewFormRef"
        :model="rejectReviewForm"
        :rules="rejectReviewRules"
        label-width="100px"
      >
        <el-form-item label="拒绝原因" prop="reason">
          <el-input
            v-model="rejectReviewForm.reason"
            type="textarea"
            :rows="3"
            placeholder="请输入拒绝原因"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="rejectReviewDialogVisible = false">取消</el-button>
        <el-button type="danger" @click="submitRejectReview">确认拒绝</el-button>
      </template>
    </el-dialog>

    <!-- 添加转审核对话框 -->
    <el-dialog
      v-model="reviewDialogVisible"
      title="转审核"
      width="500px"
    >
      <el-form
        ref="reviewFormRef"
        :model="reviewForm"
        :rules="reviewRules"
        label-width="100px"
      >
        <el-form-item label="审核人" prop="reviewer_username">
          <el-input 
            v-model="reviewForm.reviewer_username" 
            placeholder="请输入审核人用户名"
            @blur="validateReviewerUsername"
          >
            <template #prefix>
              <el-icon><User /></el-icon>
            </template>
            <template #suffix>
              <el-icon v-if="reviewerValidateStatus === 'validating'" class="is-loading">
                <Loading />
              </el-icon>
              <el-icon v-else-if="reviewerValidateStatus === 'success'" color="#67C23A">
                <CircleCheckFilled />
              </el-icon>
              <el-icon v-else-if="reviewerValidateStatus === 'error'" color="#F56C6C">
                <CircleCloseFilled />
              </el-icon>
            </template>
          </el-input>
          <div class="username-tip" :class="reviewerValidateStatus" v-if="reviewerTip">
            {{ reviewerTip }}
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="reviewDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitReview">确认</el-button>
      </template>
    </el-dialog>

    <!-- 添加分类对话框 -->
    <el-dialog
      v-model="categoryDialogVisible"
      title="需求归类"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="categoryForm">
        <el-form-item label="选择归类">
          <el-select v-model="categoryForm.category" placeholder="请选择">
            <el-option
              v-for="option in categoryOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="categoryDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitCategory">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 修改归类管理对话框 -->
    <el-dialog
      v-model="categoryManageDialogVisible"
      title="归类管理"
      width="400px"
    >
      <!-- 分类列表 -->
      <div class="category-list">
        <div v-for="category in categories" :key="category.id" class="category-item">
          <span class="category-name">{{ category.name }}</span>
          <el-button 
            type="danger" 
            link
            class="delete-btn"
            @click="deleteCategory(category)"
          >
            删除
          </el-button>
        </div>
      </div>

      <!-- 新分类输入框列表 -->
      <div v-for="(_, index) in newCategoryInputs" :key="index" class="category-input-item">
        <el-input
          v-model="newCategoryInputs[index]"
          placeholder="请输入分类名称"
          size="default"
        />
      </div>

      <!-- 添加按钮 -->
      <el-button 
        type="primary" 
        class="add-category-btn"
        plain
        @click="addCategoryInput"
      >
        <el-icon><Plus /></el-icon>添加归类
      </el-button>

      <!-- 对话框底部按钮 -->
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="categoryManageDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveCategoryChanges">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
  
<script setup>
import { ref, onMounted, computed, watch, onUnmounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus, Search, InfoFilled, User, Loading, CircleCheckFilled, CircleCloseFilled, Calendar, Collection, Setting, Delete, Bell } from '@element-plus/icons-vue'
import { formatDate, formatDateOnly } from '@/utils/date'
import axios from '@/utils/axios'
import { request } from '@/utils/axios';
import { useStore } from 'vuex'
import { getRequirements, batchDeleteRequirements, deleteRequirements, updateRequirementStatus, updateRequirementCategory, getCategories } from '@/api/requirements'

const store = useStore()

// 状态变量
const activeTab = ref('received')
const loading = ref(false)
const requirements = ref([])
const currentRequirement = ref(null)

// 对话框显示状态
const createDialogVisible = ref(false)
const detailDialogVisible = ref(false)
const acceptDialogVisible = ref(false)
const milestoneDialogVisible = ref(false)
const rejectReviewDialogVisible = ref(false)
const reviewDialogVisible = ref(false)
const categoryDialogVisible = ref(false)
const categoryManageDialogVisible = ref(false)

// 筛选条件
const filters = ref({
  project_id: '',
  status: ''
})

// 表单对象
const requirementForm = ref({
  title: '',        // 需求标题
  content: '',      // 需求内容
  contact: '',      // 联系方式
  deadline: '',     // 期望完成时间
  receiver: ''      // 接收人
})

const acceptForm = ref({
  category: ''
})

const milestoneForm = ref({
  description: '',
  progress: '',
  milestone_date: null
})

const rejectReviewForm = ref({
  reason: ''
})

const reviewForm = ref({
  reviewer_username: ''
})

const categoryForm = ref({
  category: ''
})

// 表单验证规则
const createRules = {
  title: [
    { required: true, message: '请输入需求标题', trigger: 'blur' },
    { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
  ],
  content: [
    { required: true, message: '请输入需求内容', trigger: 'blur' },
    { min: 10, message: '需求内容不能少于 10 个字符', trigger: 'blur' }
  ],
  contact: [
    { required: true, message: '请输入联系方式', trigger: 'blur' },
    { min: 5, max: 50, message: '长度在 5 到 50 个字符', trigger: 'blur' }
  ],
  deadline: [
    { required: true, message: '请选择期望完成时间', trigger: 'change' }
  ],
  receiver: [
    { required: true, message: '请输入接收人', trigger: 'change' }
  ]
}

const acceptRules = {
  category: [
    { required: true, message: '请输入需求归类', trigger: 'blur' }
  ]
}

const milestoneRules = {
  description: [
    { required: true, message: '请输入里程碑说明', trigger: 'blur' }
  ],
  progress: [
    { required: true, message: '请输入当前进度', trigger: 'blur' }
  ],
  milestone_date: [
    { required: true, message: '请选择时间', trigger: 'change' }
  ]
}

const rejectReviewRules = {
  reason: [
    { required: true, message: '请输入拒绝原因', trigger: 'blur' },
    { min: 5, message: '拒绝原因不能少于 5 个字符', trigger: 'blur' }
  ]
}

const reviewRules = {
  reviewer_username: [
    { required: true, message: '请输入审核人用户名', trigger: 'blur' }
  ]
}

const categoryRules = {
  category: [
    { required: true, message: '请选择归类', trigger: 'change' }
  ]
}

// 添加用户名验证相关的状态
const usernameValidateStatus = ref('')  // '' | 'validating' | 'success' | 'error'
const usernameTip = ref('')

// 添加转审核相关的状态
const reviewerValidateStatus = ref('')
const reviewerTip = ref('')

// 获取未读数量
const unreadSubmittedCount = computed(() => store.state.unreadSubmittedCount)
const unreadReviewCount = computed(() => store.state.unreadReviewCount || 0)

// 添加分类选项
const categoryOptions = ref([])

// 添加归类管理相关的数据
const categories = ref([])

// 控制添加输入框的显示
const showAddCategoryInput = ref(false)
// 新分类的名称
const newCategoryName = ref('')

// 存储新分类的输入框列表
const newCategoryInputs = ref([])

// 添加新的输入框
const addCategoryInput = () => {
  newCategoryInputs.value.push('')
}

// 检查分类名称是否重复
const isDuplicateCategory = (name) => {
  // 检查现有分类
  const existingDuplicate = categories.value.some(cat => cat.name === name)
  // 检查新添加的分类
  const newDuplicate = newCategoryInputs.value.filter(input => input === name).length > 1
  return existingDuplicate || newDuplicate
}

// 保存所有分类变更
const saveCategoryChanges = async () => {
  try {
    // 过滤掉空输入
    const newCategories = newCategoryInputs.value
      .filter(name => name.trim() !== '')
      .map(name => ({ name: name.trim() }))

    // 检查是否有重复的分类名称
    for (const category of newCategories) {
      if (isDuplicateCategory(category.name)) {
        ElMessage.warning(`分类 "${category.name}" 已存在`)
        return
      }
    }

    // 合并现有分类和新分类
    const allCategories = [...categories.value, ...newCategories]
    
    // 调用后端 API 保存所有分类
    const response = await request.post('/api/categories', {
      categories: allCategories
    })
    
    if (response) {
      // 更新本地数据
      categories.value = response
      // 清空输入框列表
      newCategoryInputs.value = []
      // 关闭对话框
      categoryManageDialogVisible.value = false
      ElMessage.success('保存成功')
    }
  } catch (error) {
    console.error('保存失败:', error)
    ElMessage.error('保存失败')
  }
}

// 生命周期钩子
onMounted(async () => {
  console.log('组件挂载 - 初始标签页:', activeTab.value)
  try {
    // 并行加载数据
    await Promise.all([
      loadRequirements(),
      loadCategories(true), // 使用静默模式加载分类
      store.dispatch('fetchUnreadCount')
    ])
  } catch (error) {
    console.error('初始化数据加载失败:', error)
  }
  
  // 获取初始未读数量
  if (store.dispatch('fetchUnreadReviewCount')) {
    await store.dispatch('fetchUnreadReviewCount')
  }
  
  // 设置定时刷新
  const timer = setInterval(() => {
    store.dispatch('fetchUnreadReviewCount')
  }, 60000) // 每分钟刷新一次

  // 组件卸载时清除定时器
  onUnmounted(() => {
    clearInterval(timer)
  })
})

// 修改标签页切换处理函数
const handleTabChange = async (tab) => {
  activeTab.value = tab.props.name
  await loadRequirements()
  
  // 如果切换到"我提出的需求"标签，重新获取未读数量
  if (tab.props.name === 'submitted') {
    await store.dispatch('fetchUnreadCount')
  }
}

// 修改 loadRequirements 函数，添加更多日志
const loadRequirements = async () => {
  try {
    loading.value = true
    await store.dispatch('fetchUnreadCount')
    const params = {
      tab: activeTab.value,
      status: filters.value.status || undefined
    }
    console.log('加载需求列表 - 当前标签页:', activeTab.value)
    console.log('发送请求参数:', params)
    
    const response = await axios.get('/api/requirements', { params })
    console.log('API 完整响应:', response)
    
    if (Array.isArray(response)) {
      requirements.value = response
      // 计算未读数量
      if (activeTab.value === 'submitted') {
        unreadSubmittedCount.value = requirements.value.filter(
          req => req.has_updates && !req.updates_read
        ).length
      }
    } else if (Array.isArray(response.data)) {
      requirements.value = response.data
      // 计算未读数量
      if (activeTab.value === 'submitted') {
        unreadSubmittedCount.value = requirements.value.filter(
          req => req.has_updates && !req.updates_read
        ).length
      }
    } else {
      console.warn('API返回的数据格式不符合预期:', response)
      requirements.value = []
    }
    
  } catch (error) {
    console.error('加载需求列表失败:', error)
    ElMessage.error('加载需求列表失败')
  } finally {
    loading.value = false
  }
}

// 修改加载归类选项的方法，添加详细日志
const loadCategoryOptions = async (silent = false) => {
  console.log('===== 开始加载归类选项 =====')
  console.log('静默模式:', silent)
  
  try {
    console.log('发送请求: GET /api/categories')
    const response = await axios.get('/api/categories')
    console.log('原始响应数据:', response)
    
    // 处理不同的返回数据格式
    let categories = []
    if (Array.isArray(response)) {
      console.log('响应是数组格式')
      categories = response
    } else if (Array.isArray(response.data)) {
      console.log('响应的 data 是数组格式')
      categories = response.data
    } else if (response?.data?.data && Array.isArray(response.data.data)) {
      console.log('响应的 data.data 是数组格式')
      categories = response.data.data
    } else {
      console.warn('无法识别的响应格式:', response)
    }

    console.log('解析后的归类数据:', categories)

    // 转换为选项格式
    categoryOptions.value = categories.map(item => ({
      label: item.name,
      value: item.name
    }))
    
    console.log('处理后的归类选项:', categoryOptions.value)
  } catch (error) {
    console.error('加载归类选项失败:', error)
    console.error('错误详情:', {
      message: error.message,
      response: error.response,
      config: error.config
    })
    categoryOptions.value = []
    if (!silent) {
      ElMessage.error('加载归类选项失败')
    }
  } finally {
    console.log('===== 加载归类选项结束 =====')
  }
}

// 修改接受需求的处理方法
const handleAccept = async (row) => {
  try {
    currentRequirement.value = row
    // 先加载最新的归类选项
    await loadCategoryOptions()
    // 重置分类表单
    categoryForm.value.category = ''
    // 打开归类对话框
    categoryDialogVisible.value = true
  } catch (error) {
    console.error('准备接受需求失败:', error)
    ElMessage.error('加载归类选项失败')
  }
}

const handleReview = (row) => {
  currentRequirement.value = row
  reviewForm.value = {
    reviewer_username: ''
  }
  reviewerValidateStatus.value = ''
  reviewerTip.value = ''
  reviewDialogVisible.value = true
}

const handleCommunicate = async (row) => {
  try {
    await axios.put(`/api/requirements/${row.id}/status`, { status: 'communicating' })
    ElMessage.success('已转为中止任务状态')
    await loadRequirements()
  } catch (error) {
    console.error('更新状态失败:', error)
    ElMessage.error('操作失败')
  }
}

const showMilestoneDialog = (row) => {
  currentRequirement.value = row
  milestoneForm.value = {
    description: '',
    progress: '',
    milestone_date: new Date()
  }
  milestoneDialogVisible.value = true
}

const submitMilestone = async () => {
  if (!currentRequirement.value) return;
  
  // 验证进度值
  const progress = parseFloat(milestoneForm.value.progress);
  if (isNaN(progress) || progress < 0 || progress > 100) {
    ElMessage.error('请输入0-100之间的有效进度值');
    return;
  }
  
  try {
    // 确保发送到后端的是数字类型
    const formData = {
      ...milestoneForm.value,
      progress: progress
    };
    
    await axios.post(`/api/requirements/${currentRequirement.value.id}/milestones`, formData);
    ElMessage.success('里程碑添加成功');
    milestoneDialogVisible.value = false;
    await loadRequirements();
  } catch (error) {
    console.error('添加里程碑失败:', error);
    ElMessage.error('添加里程碑失败');
  }
};

const markAsCompleted = async (row) => {
  try {
    // 1. 先更新状态为已完成
    await request.put(`/api/requirements/${row.id}/status`, { 
      status: 'completed' 
    });

    // 2. 添加完成里程碑
    const milestoneData = {
      description: '需求已完成',
      progress: 100,
      milestone_date: new Date().toISOString().split('.')[0]
    };
    
    await request.post(`/api/requirements/${row.id}/milestones`, milestoneData);
    
    ElMessage.success('需求已标记为完成');
    await loadRequirements();
  } catch (error) {
    console.error('更新状态失败:', error);
    ElMessage.error(error.response?.data?.message || '操作失败');
  }
};

const handleAcceptCompletion = async (row) => {
  try {
    // 添加确认对话框
    await ElMessageBox.confirm(
      '确认通过此需求的验收？',
      '验收确认',
      {
        confirmButtonText: '确认通过',
        cancelButtonText: '取消',
        type: 'success'
      }
    );

    await request.put(`/api/requirements/${row.id}/status`, {
      status: 'accepted_completion'
    });

    // 2. 添加验收通过里程碑
    const milestoneData = {
      description: '需求已验收通过',
      progress: 100,
      milestone_date: new Date().toISOString().split('.')[0]
    };
    
    await request.post(`/api/requirements/${row.id}/milestones`, milestoneData);

    ElMessage.success('已通过验收');
    await loadRequirements();
  } catch (error) {
    if (error !== 'cancel') { // 用户取消不显示错误
      console.error('验收操作失败:', error);
      ElMessage.error('验收操作失败');
    }
  }
};

const handleRejectCompletion = async (row) => {
  try {
    // 添加带输入框的确认对话框
    const { value: rejectReason } = await ElMessageBox.prompt(
      '请输入拒绝验收的原因',
      '拒绝验收',
      {
        confirmButtonText: '确认拒绝',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入拒绝原因...',
        inputValidator: (value) => {
          if (!value.trim()) {
            return '拒绝原因不能为空';
          }
          return true;
        },
        type: 'warning'
      }
    );

    // 1. 更新状态为拒绝验收
    await request.put(`/api/requirements/${row.id}/status`, {
      status: 'rejected_completion',
      reject_reason: rejectReason.trim()
    });
    
    // 2. 添加拒绝验收的里程碑
    const milestoneData = {
      description: `需求验收被拒绝：${rejectReason.trim()}`,
      progress: 0,  // 拒绝时进度设为0
      milestone_date: new Date().toISOString().split('.')[0]
    };
    
    await request.post(`/api/requirements/${row.id}/milestones`, milestoneData);
    
    ElMessage.success('已拒绝验收');
    await loadRequirements();
  } catch (error) {
    if (error !== 'cancel') { // 用户取消不显示错误
      console.error('验收操作失败:', error);
      ElMessage.error('验收操作失败');
    }
  }
};

// 查看详情
const showDetailDialog = async (row) => {
  try {
    // 获取详细信息，包括里程碑
    const response = await axios.get(`/api/requirements/${row.id}`)
    currentRequirement.value = response
    detailDialogVisible.value = true

    // 如果是审核标签页，标记为已读并更新未读数量
    if (activeTab.value === 'reviewing' && !row.reviewer_read) {
      await store.dispatch('markReviewAsRead', row.id)
      await store.dispatch('fetchUnreadReviewCount')  // 更新未读数量
    }
  } catch (error) {
    console.error('获取需求详情失败:', error)
    ElMessage.error('获取需求详情失败')
  }
}

// 通过审核
const handleApproveReview = async (row) => {
  try {
    // 添加确认对话框
    await ElMessageBox.confirm(
      '确认通过此需求的审核？',
      '审核确认',
      {
        confirmButtonText: '确认通过',
        cancelButtonText: '取消',
        type: 'info'
      }
    );

    await request.put(`/api/requirements/${row.id}/status`, {
      status: 'review_approved'
    })
    
    ElMessage.success('审核已通过')
    await store.dispatch('fetchUnreadReviewCount')  // 更新未读数量
    await loadRequirements()
  } catch (error) {
    if (error !== 'cancel') {
      console.error('审核操作失败:', error)
      ElMessage.error('审核操作失败')
    }
  }
}

// 提交拒绝审核
const submitRejectReview = async () => {
  if (!rejectReviewForm.value.reason) {
    ElMessage.error('请输入拒绝原因')
    return
  }

  try {
    await axios.put(`/api/requirements/${currentRequirement.value.id}/status`, {
      status: 'review_rejected',
      review_reason: rejectReviewForm.value.reason
    })
    ElMessage.success('已拒绝审核')
    rejectReviewDialogVisible.value = false
    await store.dispatch('fetchUnreadReviewCount')  // 更新未读数量
    await loadRequirements()
  } catch (error) {
    console.error('拒绝审核失败:', error)
    ElMessage.error('拒绝审核失败')
  }
}

// 添加审核人用户名验证方法
const validateReviewerUsername = async () => {
  const username = reviewForm.value.reviewer_username
  
  if (!username) {
    reviewerValidateStatus.value = ''
    reviewerTip.value = ''
    return
  }

  reviewerValidateStatus.value = 'validating'
  reviewerTip.value = '正在验证...'
  
  try {
    const response = await axios.get(`/api/users/validate/${username}`)
    if (response && response.username) {
      reviewerValidateStatus.value = 'success'
      reviewerTip.value = `用户验证成功: ${username}`
      return true
    } else {
      reviewerValidateStatus.value = 'error'
      reviewerTip.value = '用户不存在'
      return false
    }
  } catch (error) {
    reviewerValidateStatus.value = 'error'
    reviewerTip.value = '验证失败，请重试'
    return false
  }
}

// 添加提交转审核的方法
const submitReview = async () => {
  if (reviewerValidateStatus.value !== 'success') {
    ElMessage.error('请输入有效的审核人用户名')
    return
  }

  try {
    await axios.put(`/api/requirements/${currentRequirement.value.id}/status`, {
      status: 'reviewing',
      reviewer_username: reviewForm.value.reviewer_username
    })
    ElMessage.success('已转交审核')
    reviewDialogVisible.value = false
    await loadRequirements()
  } catch (error) {
    console.error('转审核失败:', error)
    ElMessage.error('转审核失败')
  }
}

// 修改状态类型映射
const getStatusType = (status) => {
  const types = {
    pending: 'info',
    accepted: 'primary',
    communicating: 'warning',
    reviewing: 'warning',
    review_approved: 'success',
    review_rejected: 'danger',
    completed: 'success',
    accepted_completion: 'success',
    rejected_completion: 'danger'
  }
  return types[status] || 'info'
}

// 修改状态标签映射
const getStatusLabel = (status) => {
  const labels = {
    pending: '待处理',
    accepted: '处理中',
    communicating: '中止任务',
    reviewing: '待审核',
    review_approved: '审核通过',
    review_rejected: '审核拒绝',
    completed: '待验收',
    accepted_completion: '已完成',
    rejected_completion: '已拒绝'
  }
  return labels[status] || status
}

const getTimelineItemType = (milestone) => {
  const types = {
    pending: 'info',
    accepted: 'success',
    reviewing: 'warning',
    communicating: 'warning',
    completed: 'success',
    rejected: 'danger'
  }
  return types[milestone.status] || 'info'
}

// 禁用今天之前的日期
const disabledDate = (time) => {
  return time.getTime() < Date.now() - 8.64e7
}

// 添加分页相关的状态
const currentPage = ref(1)
const pageSize = ref(20)

// 计算当前页数据
const currentPageData = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value
  const end = start + pageSize.value
  return requirements.value.slice(start, end)
})

// 分页处理方法
const handleSizeChange = (val) => {
  pageSize.value = val
  currentPage.value = 1
}

const handleCurrentChange = (val) => {
  currentPage.value = val
}

// 修改表格行的样式
const tableRowClassName = ({ row }) => {
  const classes = []
  
  // 审核未读样式
  if (activeTab.value === 'reviewing' && row.status === 'reviewing' && !row.reviewed) {
    classes.push('unread-row')
  }
  
  // 状态更新未读样式
  if (activeTab.value === 'submitted' && row.has_updates && !row.updates_read) {
    classes.push('updated-row')
  }
  
  // 交替行样式
  classes.push(row.index % 2 === 0 ? 'even-row' : 'odd-row')
  
  return classes.join(' ')
}

// 添加未读状态更新方法
const updateReviewStatus = async (row) => {
  if (activeTab.value === 'reviewing' && row.status === 'reviewing' && !row.reviewed) {
    try {
      await axios.put(`/api/requirements/${row.id}/review-status`, { reviewed: true })
      row.reviewed = true
    } catch (error) {
      console.error('更新已读状态失败:', error)
    }
  }
}

// 添加计算剩余天数的方法
const getRemainingDays = (expectedDate) => {
  const today = new Date()
  const expected = new Date(expectedDate)
  const diffTime = expected - today
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  
  if (diffDays < 0) {
    return `已超期 ${Math.abs(diffDays)} 天`
  } else if (diffDays === 0) {
    return '今天到期'
  } else {
    return `还剩 ${diffDays} 天`
  }
}

// 获取时间状态的样式
const getTimeStatusStyle = (expectedDate) => {
  const today = new Date()
  const expected = new Date(expectedDate)
  const diffDays = Math.ceil((expected - today) / (1000 * 60 * 60 * 24))
  
  if (diffDays < 0) {
    return 'color: #f56c6c;' // 红色表示超期
  } else if (diffDays <= 3) {
    return 'color: #e6a23c;' // 黄色表示即将到期
  } else {
    return 'color: #67c23a;' // 绿色表示正常
  }
}

// 修改时间进度计算方法
const getTimeBasedProgress = (requirement) => {
  const startDate = new Date(requirement.created_at);
  const endDate = new Date(requirement.expected_completion_date);
  const currentDate = new Date();

  // 计算总时间跨度（毫秒）
  const totalDuration = endDate - startDate;
  // 计算已经过去的时间（毫秒）
  const elapsedDuration = currentDate - startDate;

  // 计算进度百分比
  let progress = (elapsedDuration / totalDuration) * 100;

  // 限制进度在0-100之间
  progress = Math.max(0, Math.min(progress, 100));

  // 返回保留一位小数的进度值
  return parseFloat(progress.toFixed(1));
};

// 修改进度状态判断方法
const getProgressStatus = (requirement) => {
  if (!requirement) return '';
  
  // 如果已完成，显示成功状态
  if (requirement.status === 'accepted_completion') {
    return 'success';
  }
  
  // 如果已拒绝，显示异常状态
  if (requirement.status === 'rejected_completion') {
    return 'exception';
  }
  
  const currentDate = new Date();
  const endDate = new Date(requirement.expected_completion_date);
  
  // 如果已超期，显示异常状态
  if (currentDate > endDate) {
    return 'exception';
  }
  
  // 如果剩余时间不足3天，显示警告状态
  const daysRemaining = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
  if (daysRemaining <= 3) {
    return 'warning';
  }
  
  return '';
};

// 使用计算属性对里程碑进行排序
const sortedMilestones = computed(() => {
  if (!currentRequirement.value?.milestones) return [];
  return [...currentRequirement.value.milestones].sort((a, b) => 
    new Date(b.milestone_date) - new Date(a.milestone_date)
  );
});

// 获取最新里程碑日期
const getLatestMilestoneDate = (requirement) => {
  if (!requirement?.milestones?.length) {
    return '暂无进展';
  }
  const latestMilestone = requirement.milestones.sort((a, b) => 
    new Date(b.milestone_date) - new Date(a.milestone_date)
  )[0];
  return formatDate(latestMilestone.milestone_date);
};

// 获取最新里程碑
const getLatestMilestone = (requirement) => {
  if (!requirement?.milestones?.length) {
    return null;
  }
  return requirement.milestones.sort((a, b) => 
    new Date(b.milestone_date) - new Date(a.milestone_date)
  )[0];
};

// 获取里程碑类型
const getMilestoneType = (progress) => {
  const num = Number(progress);
  if (num >= 100) return 'success';
  if (num >= 50) return 'warning';
  return 'primary';
};

// 获取里程碑状态
const getMilestoneStatus = (progress) => {
  const num = Number(progress);
  if (num >= 100) return 'success';
  if (num >= 50) return 'warning';
  return '';
};

// 添加显示对话框的方法
const showCreateDialog = () => {
  createDialogVisible.value = true
}

const showCategoryManageDialog = async () => {
  categoryManageDialogVisible.value = true
  await loadCategories() // 重新加载分类列表
}

// 显示添加输入框
const showAddInput = () => {
  showAddCategoryInput.value = true
}

// 添加分类到临时列表
const addCategory = () => {
  if (!newCategoryName.value.trim()) {
    ElMessage.warning('请输入分类名称')
    return
  }

  // 只添加到本地列表，不调用后端
  categories.value.push({
    name: newCategoryName.value.trim()
  })
  
  // 清空输入框，准备下一次输入
  newCategoryName.value = ''
}

// 删除分类
const deleteCategory = async (category) => {
  try {
    // 对名称进行 URL 编码
    const encodedName = encodeURIComponent(category.name)
    
    // 使用 DELETE 方法和正确的路径格式
    await request.delete(`/api/categories/${encodedName}`)
    
    // 删除成功后，更新前端显示
    const index = categories.value.findIndex(item => item.id === category.id)
    if (index !== -1) {
      categories.value.splice(index, 1)
    }
    
    ElMessage.success('删除成功')
  } catch (error) {
    console.error('删除失败:', error)
    ElMessage.error('删除失败')
  }
}

// 添加一个响应式变量存储验证结果
const receiverValidateMessage = ref('')
const receiverValidateType = ref('') // 用于控制消息的样式：success/warning

// 修改验证方法
const validateReceiver = async (username) => {
  if (!username || !username.trim()) {
    // 当用户名为空时，不显示任何验证消息
    receiverValidateMessage.value = ''
    receiverValidateType.value = ''
    return false
  }
  
  try {
    const response = await request.get(`/api/users/validate/${username}`)
    const exists = response && response.username !== null
    
    if (exists) {
      receiverValidateMessage.value = '用户名有效'
      receiverValidateType.value = 'success'
      return true
    } else {
      receiverValidateMessage.value = '用户名不存在'
      receiverValidateType.value = 'warning'
      return false
    }
  } catch (error) {
    console.error('验证用户失败:', error)
    receiverValidateMessage.value = '验证失败'
    receiverValidateType.value = 'error'
    return false
  }
}

// 监听用户名变化
watch(() => requirementForm.value.receiver, (newValue) => {
  if (!newValue || !newValue.trim()) {
    receiverValidateMessage.value = ''
    receiverValidateType.value = ''
  }
})

// 移除重复的 formatDateOnly 定义，使用已有的或重命名
const formatSubmitDate = (date) => {
  if (!date) return '';
  if (typeof date === 'string') return date.split('T')[0];
  return date.toISOString().split('T')[0];
};

// 修改提交需求的方法
const submitRequirement = async () => {
  try {
    // 1. 先验证表单
    await createFormRef.value.validate();
    
    // 2. 验证接收人
    if (!requirementForm.value?.receiver) {
      ElMessage.error('请输入接收人用户名');
      return;
    }

    // 3. 验证所有必填字段
    if (!requirementForm.value?.title || 
        !requirementForm.value?.content || 
        !requirementForm.value?.deadline) {
      ElMessage.error('请填写所有必填字段');
      return;
    }

    // 4. 格式化数据
    const formData = {
      title: requirementForm.value.title.trim(),
      description: requirementForm.value.content.trim(),
      contact: requirementForm.value.contact?.trim() || '',
      expected_completion_date: formatSubmitDate(requirementForm.value.deadline),
      receiver_username: requirementForm.value.receiver.trim()
    };

    // 5. 提交需求
    await request.post('/api/requirements', formData);

    // 6. 提交成功后的处理
    ElMessage.success('需求提交成功');
    createDialogVisible.value = false;
    
    // 7. 重置表单
    requirementForm.value = {
      title: '',
      content: '',
      contact: '',
      deadline: '',
      receiver: ''
    };

    // 8. 重新加载需求列表
    await loadRequirements();
    
  } catch (error) {
    console.error('提交需求失败:', error);
    if (error.response?.data?.message) {
      ElMessage.error(error.response.data.message);
    } else {
      ElMessage.error('提交需求失败');
    }
  }
};

// 在 script setup 中添加表单引用
const createFormRef = ref(null)

// 添加选中行的数组
const selectedRows = ref([])

// 处理选择变化
const handleSelectionChange = (rows) => {
  console.log('选择变化:', rows)
  selectedRows.value = rows
}

// 处理批量删除
const handleBatchDelete = () => {
  if (selectedRows.value.length === 0) {
    return
  }

  ElMessageBox.confirm(
    `确定要删除选中的 ${selectedRows.value.length} 条需求吗？此操作不可恢复。`,
    '警告',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(async () => {
    try {
      const ids = selectedRows.value.map(row => row.id)
      await batchDeleteRequirements(ids)
      ElMessage.success('删除成功')
      await loadRequirements() // 确保你有这个方法来刷新需求列表
      selectedRows.value = [] // 清空选中项
    } catch (error) {
      console.error('删除失败:', error)
      ElMessage.error(error.response?.data?.message || '删除失败')
    }
  }).catch(() => {
    // 用户点击取消，不做任何操作
  })
}

// 判断是否可以删除
const canDelete = (row) => {
  return row.status === 'pending'
}

// 处理删除
const handleDelete = async () => {
  console.log('=== 删除操作开始 ===')
  if (!selectedRows.value.length) {
    console.log('没有选中任何行')
    return
  }

  try {
    await ElMessageBox.confirm(
      `确定要删除选中的 ${selectedRows.value.length} 条需求吗？此操作不可恢复。`,
      '警告',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    )
    
    const ids = selectedRows.value.map(row => row.id)
    console.log('要删除的ID列表:', ids)
    
    const response = await deleteRequirements(ids)  // 使用正确的函数名
    console.log('删除请求响应:', response)
    
    ElMessage.success('删除成功')
    await loadRequirements()
    selectedRows.value = []
  } catch (error) {
    if (error !== 'cancel') {
      console.error('删除失败:', error)
      ElMessage.error(error.response?.data?.message || '删除失败')
    }
  }
}

// 修改提交归类的方法
const submitCategory = async () => {
  try {
    if (!categoryForm.value.category) {
      ElMessage.warning('请选择归类')
      return
    }

    if (!currentRequirement.value) {
      ElMessage.error('未找到需要归类的需求')
      return
    }

    // 更新需求的归类和状态
    await updateRequirementCategory(currentRequirement.value.id, categoryForm.value.category)

    ElMessage.success('需求已接受并归类')
    categoryDialogVisible.value = false
    await loadRequirements()
  } catch (error) {
    console.error('接受需求失败:', error)
    ElMessage.error('接受需求失败')
  }
}

// 获取分类列表
const loadCategories = async (silent = false) => {
  try {
    console.log('开始加载分类列表')
    const response = await request.get('/api/categories')
    console.log('分类列表API响应:', response)
    
    if (Array.isArray(response)) {
      categories.value = response
      // 同时更新分类选项
      categoryOptions.value = response.map(cat => ({
        label: cat.name,
        value: cat.name
      }))
    } else {
      console.warn('API返回的数据格式不符合预期:', response)
      categories.value = []
      categoryOptions.value = []
    }
    
    console.log('更新后的分类列表:', categories.value)
    console.log('更新后的分类选项:', categoryOptions.value)
  } catch (error) {
    console.error('加载分类列表失败:', error)
    if (!silent) {
      ElMessage.error('加载分类列表失败')
    }
    categories.value = []
    categoryOptions.value = []
  }
}
</script>

<style scoped>
.requirement-view {
  padding: 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.custom-tabs {
  margin-bottom: 24px;
}

.operation-bar {
  margin-bottom: 24px;
  padding: 16px;
  background: #f5f7fa;
  border-radius: 4px;
}

.search-form {
  display: flex;
  align-items: center;
}

.status-select {
  width: 160px;
}

.requirement-title {
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
}

:deep(.el-table) {
  margin-bottom: 16px;
  
  .even-row {
    background-color: #fafafa;
  }
  
  .odd-row {
    background-color: #ffffff;
  }
  
  th {
    font-weight: 600;
  }
  
  td {
    padding: 12px 0;
  }

  .unread-row {
    background-color: #fdf6ec;
    &:hover > td {
      background-color: #faecd8 !important;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #ebeef5;
}

/* 优化标签样式 */
:deep(.el-tag) {
  padding: 0 12px;
  height: 28px;
  line-height: 26px;
  border-radius: 4px;
}

/* 优化按钮样式 */
:deep(.el-button) {
  padding: 8px 16px;
  border-radius: 4px;
}

/* 优化表单样式 */
:deep(.el-form-item) {
  margin-bottom: 0;
  margin-right: 16px;
}

.username-tip {
  margin-top: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.username-tip.success {
  color: #67C23A;
}

.username-tip.error {
  color: #F56C6C;
}

.username-tip.validating {
  color: #909399;
}

/* 优化审核标签页的样式 */
.review-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.review-badge {
  transform: scale(0.8);
  transform-origin: right center;
}

/* 优化状态标签样式 */
:deep(.el-tag) {
  padding: 0 12px;
  height: 28px;
  line-height: 26px;
  border-radius: 4px;
  
  &.el-tag--success {
    --el-tag-bg-color: var(--el-color-success-light-9);
  }
  
  &.el-tag--warning {
    --el-tag-bg-color: var(--el-color-warning-light-9);
  }
  
  &.el-tag--danger {
    --el-tag-bg-color: var(--el-color-danger-light-9);
  }
}

/* 添加里程碑列表样式 */
.milestone-list {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid var(--el-border-color-light);

  h3 {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    color: var(--el-text-color-primary);
  }
}

:deep(.el-timeline-item__content) {
  h4 {
    margin: 0 0 8px;
    font-size: 14px;
    color: var(--el-text-color-primary);
  }

  p {
    margin: 0;
    font-size: 13px;
    color: var(--el-text-color-secondary);
  }
}

/* 详情对话框样式 */
:deep(.requirement-detail-dialog) {
  .el-dialog__body {
    padding: 0;
  }
}

.detail-status-card {
  padding: 20px;
  background: linear-gradient(135deg, var(--el-color-primary-light-8) 0%, var(--el-color-primary-light-9) 100%);
  
  &.completed, &.accepted_completion {
    background: linear-gradient(135deg, var(--el-color-success-light-8) 0%, var(--el-color-success-light-9) 100%);
  }
  
  &.reviewing {
    background: linear-gradient(135deg, var(--el-color-warning-light-8) 0%, var(--el-color-warning-light-9) 100%);
  }
}

.status-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.status-info {
  display: flex;
  align-items: center;
  gap: 12px;
  
  .status-icon {
    font-size: 24px;
    color: var(--el-color-primary);
  }
  
  .status-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .label {
      font-size: 13px;
      color: var(--el-text-color-secondary);
    }
  }
}

.status-timeline {
  display: flex;
  align-items: center;
  gap: 24px;
  
  .timeline-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .timeline-label {
      font-size: 13px;
      color: var(--el-text-color-secondary);
    }
    
    .timeline-value {
      font-size: 14px;
      color: var(--el-text-color-primary);
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 8px;
      
      .remaining-days-tag {
        display: inline-block;
        padding: 2px 8px;
        font-size: 12px;
        line-height: 1.4;
        border-radius: 4px;
        background-color: var(--el-color-success-light-8);
        color: var(--el-color-success);
        margin-left: 8px;
      }
      
      /* 如果是已逾期，使用警告色 */
      .remaining-days-tag.overdue {
        background-color: var(--el-color-danger-light-8);
        color: var(--el-color-danger);
      }
      
      .info-icon {
        font-size: 14px;
        color: var(--el-color-primary);
        cursor: pointer;
      }
    }
  }
}

.status-extra {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.extra-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.extra-label {
  font-size: 13px;
  color: var(--el-text-color-secondary);
}

.extra-value {
  font-size: 14px;
  color: var(--el-text-color-primary);
  min-width: 200px;
}

:deep(.el-progress) {
  margin-bottom: 0;
}

.detail-main-card {
  padding: 24px;
  
  .detail-title {
    margin: 0 0 16px;
    font-size: 20px;
    font-weight: 600;
    color: var(--el-text-color-primary);
  }
  
  .detail-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;
    
    .meta-item {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--el-text-color-secondary);
      font-size: 14px;
      
      .el-icon {
        font-size: 16px;
      }
    }
  }
}

.section-title {
  text-align: left;
  margin: 0 0 16px;
  font-size: 16px;
  font-weight: 600;
  color: var(--el-text-color-primary);
  display: flex;
  align-items: center;
  gap: 8px;
  
  &::before {
    content: '';
    width: 4px;
    height: 16px;
    background: var(--el-color-primary);
    border-radius: 2px;
    flex-shrink: 0;
  }
}

.content-box {
  padding: 16px;
  background: var(--el-fill-color-lighter);
  border-radius: 8px;
  color: var(--el-text-color-regular);
  line-height: 1.6;
  margin-bottom: 24px;
  white-space: pre-wrap;
}

.detail-review {
  margin-bottom: 24px;
  
  .review-info {
    padding: 16px;
    background: var(--el-color-warning-light-9);
    border-radius: 8px;
    
    .review-item {
      margin-bottom: 12px;
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 8px;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      .label {
        color: var(--el-text-color-secondary);
        font-weight: 500;
      }
      
      .value {
        color: var(--el-text-color-primary);
      }
      
      .review-reason {
        margin-top: 4px;
        padding: 12px;
        /* background: #fff; */
        border-radius: 4px;
        line-height: 1.6;
        text-align: left;
      }
    }
  }
}

.detail-timeline {
  .milestone-card {
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    
    h4 {
      margin: 0 0 12px;
      font-size: 14px;
      color: var(--el-text-color-primary);
    }
    
    .progress-bar {
      .progress-text {
        margin-bottom: 8px;
        font-size: 13px;
        color: var(--el-text-color-secondary);
      }
    }
  }
}

/* 添加一些动画效果 */
.detail-status-card, .detail-main-card {
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.status-with-reason {
  display: flex;
  align-items: center;
  gap: 8px;
}

.category-list {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 4px;
}

.category-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  margin-bottom: 8px;
  background-color: var(--el-fill-color-light);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.category-item:hover {
  background-color: var(--el-fill-color);
}

.category-name {
  font-size: 14px;
  color: var(--el-text-color-primary);
  flex: 1;
}

.delete-btn {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.category-item:hover .delete-btn {
  opacity: 1;
}

.category-input-item {
  margin-bottom: 12px;
}

.add-category-btn {
  width: 100%;
  margin-top: 8px;
  border-style: dashed;
}

.add-category-btn .el-icon {
  margin-right: 4px;
}

:deep(.el-dialog__body) {
  padding-top: 16px;
  padding-bottom: 16px;
}

/* 自定义滚动条样式 */
.category-list::-webkit-scrollbar {
  width: 6px;
}

.category-list::-webkit-scrollbar-thumb {
  background-color: var(--el-border-color-lighter);
  border-radius: 3px;
}

.category-list::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 在 style 部分添加新的样式 */
.updated-row {
  background-color: #f0f9eb !important;
  
  &:hover > td {
    background-color: #e1f3d8 !important;
  }
}

/* 修改标签页样式 */
.custom-tabs {
  .el-tabs__item {
    position: relative;
  }
  
  .tab-badge {
    position: absolute;
    top: 0;
    right: -8px;
    transform: translateY(-50%);
  }
}

/* 添加更新时间样式 */
.update-time {
  font-size: 12px;
  color: var(--el-text-color-secondary);
  margin-left: 8px;
}

.status-change-indicator {
  display: inline-flex;
  align-items: center;
  padding: 2px 6px;
  border-radius: 10px;
  background-color: var(--el-color-success-light-9);
  color: var(--el-color-success);
  font-size: 12px;
  margin-left: 8px;
  
  .el-icon {
    margin-right: 4px;
    font-size: 14px;
  }
}

.milestone-info {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.no-milestone {
  color: #999;
  font-size: 12px;
}

.time-info {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 8px 0;
}

.remaining-days {
  font-weight: 500;
}

/* 添加里程碑相关样式 */
.milestone-section {
  margin-top: 24px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
}

.milestone-content {
  padding: 12px;
  background: #fff;
  border-radius: 6px;
}

.milestone-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.milestone-description {
  font-weight: 500;
  color: var(--el-text-color-primary);
}

.milestone-progress-row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.progress-label {
  font-size: 14px;
  color: var(--el-text-color-secondary);
}

.progress-wrapper {
  flex: 1;
  min-width: 130px;
}

.compact-progress {
  margin: 0;
}

.validate-message {
  font-size: 12px;
  margin-top: 4px;
  line-height: 1;
}
.validate-message.success {
  color: var(--el-color-success);
}
.validate-message.warning {
  color: var(--el-color-warning);
}
.validate-message.error {
  color: var(--el-color-danger);
}

/* 确保进度条正确显示 */
.time-progress-cell {
  padding: 0 12px;
}

:deep(.el-progress) {
  margin-bottom: 0;
}

:deep(.el-progress-bar) {
  width: 100%;
  display: inline-block;
}

:deep(.el-progress__text) {
  font-size: 14px !important;
  min-width: 45px !important;
}

.progress-wrapper {
  flex: 1;
  min-width: 130px;
}

/* 确保进度条样式正确 */
:deep(.el-progress-bar__outer) {
  background-color: var(--el-fill-color-light);
}

:deep(.el-progress-bar__inner) {
  transition: width 0.3s ease;
}

/* 添加删除按钮样式 */
:deep(.el-button--danger.el-button--link) {
  &:hover {
    color: #ff7875 !important;
  }
  &:active {
    color: #d9363e !important;
  }
}

/* 添加批量操作按钮样式 */
.operation-bar {
  margin-bottom: 16px;
}

:deep(.el-table__header) {
  th.el-table-column--selection {
    .cell {
      padding-right: 10px;
    }
  }
}

/* 添加需求描述的样式 */
.requirement-description {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  margin: 0;
  line-height: 1.6;
  color: var(--el-text-color-regular);
}
</style>
