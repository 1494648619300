<template>
    <div class="app-header">
      <div class="left">
        <el-button type="text" @click="toggleCollapse">
          <el-icon :size="20">
            <Fold v-if="!props.isCollapse" />
            <Expand v-else />
          </el-icon>
        </el-button>
      </div>
  
      <div class="right">
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="user-info">
            <el-avatar :size="32" :src="userAvatar">
              {{ userInfo?.username?.charAt(0)?.toUpperCase() }}
            </el-avatar>
            <span class="username">{{ userInfo?.username }}</span>
            <el-icon><CaretBottom /></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <!-- <el-dropdown-item command="profile">
                <el-icon><User /></el-icon>个人信息
              </el-dropdown-item>
              <el-dropdown-item command="settings">
                <el-icon><Setting /></el-icon>系统设置
              </el-dropdown-item> -->
              <el-dropdown-item divided command="logout">
                <el-icon><SwitchButton /></el-icon>退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </template>
  
  <script setup>
  import { computed } from 'vue'
  import { 
    Fold, 
    Expand, 
    CaretBottom, 
    User, 
    Setting, 
    SwitchButton 
  } from '@element-plus/icons-vue'

  import { defineProps, defineEmits } from 'vue'
  import { authApi } from '@/api/auth'
  import { ElMessage } from 'element-plus'
  import { useRouter } from 'vue-router'  // 添加这行
  const router = useRouter()  // 添加这行
  
  const props = defineProps({
    isCollapse: {
      type: Boolean,
      required: true
    }
  })
  
  const emit = defineEmits(['update:isCollapse', 'logout'])
  
  const userInfo = computed(() => {
    const userStr = localStorage.getItem('user')
    return userStr ? JSON.parse(userStr) : null
  })
  
  const userAvatar = computed(() => {
    return userInfo.value?.avatar || ''
  })
  
  const toggleCollapse = () => {
    emit('update:isCollapse', !props.isCollapse)
  }
  
  const handleCommand = async (command) => {
    switch (command) {
      case 'logout':
        try {
          await authApi.logout()
          localStorage.clear()
          ElMessage.success('退出成功')
          router.push('/login')
        } catch (error) {
          ElMessage.error('退出失败：' + error.message)
        }
        break
      case 'profile':
        // 保持原有的个人资料处理
        break
      case 'settings':
        // 保持原有的设置处理
        break
    }
  }
  </script>
  
  <style scoped lang="scss">
  .app-header {
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--el-border-color-light);
    background-color: var(--el-bg-color);
  }
  
  .left {
    display: flex;
    align-items: center;
  }
  
  .right {
    display: flex;
    align-items: center;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: var(--el-fill-color-light);
    }
  
    .username {
      margin: 0 8px;
      font-size: 14px;
      color: var(--el-text-color-primary);
    }
  }
  
  :deep(.el-dropdown-menu__item) {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    
    .el-icon {
      margin-right: 10px;
      font-size: 16px;
    }
  }
  </style>