import axios from 'axios'
import { ElMessage, ElLoading } from 'element-plus'
import router from '@/router'

// 创建 axios 实例
const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json'
    }
})

// 加载中实例
let loadingInstance = null

// 正在进行中的请求列表
const pendingRequests = new Map()

/**
 * 生成请求的唯一键
 * @param {Object} config 请求配置
 */
const generateRequestKey = (config) => {
    const { method, url, params, data } = config
    return [method, url, JSON.stringify(params), JSON.stringify(data)].join('&')
}

/**
 * 添加请求到待处理列表
 * @param {Object} config 请求配置
 */
const addPendingRequest = (config) => {
    const requestKey = generateRequestKey(config)
    config.cancelToken = config.cancelToken || new axios.CancelToken((cancel) => {
        if (!pendingRequests.has(requestKey)) {
            pendingRequests.set(requestKey, cancel)
        }
    })
}

/**
 * 移除请求从待处理列表
 * @param {Object} config 请求配置
 */
const removePendingRequest = (config) => {
    const requestKey = generateRequestKey(config)
    if (pendingRequests.has(requestKey)) {
        const cancel = pendingRequests.get(requestKey)
        cancel(requestKey)
        pendingRequests.delete(requestKey)
    }
}

// 请求拦截器
instance.interceptors.request.use(
    config => {
        // 检查是否存在重复请求，若存在则取消已发的请求
        removePendingRequest(config)
        // 将当前请求添加到待处理列表
        addPendingRequest(config)

        // 添加 loading 效果
        if (!config.hideLoading) {
            loadingInstance = ElLoading.service({
                lock: true,
                text: '加载中...',
                background: 'rgba(0, 0, 0, 0.7)'
            })
        }

        // 添加token
        const token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    error => {
        loadingInstance?.close()
        console.error('请求错误:', error)
        return Promise.reject(error)
    }
)

// 响应拦截器
instance.interceptors.response.use(
    response => {
        // 请求完成后，移除待处理列表中的请求
        removePendingRequest(response.config)
        loadingInstance?.close()
        return response.data
    },
    async error => {
        loadingInstance?.close()

        if (axios.isCancel(error)) {
            return Promise.reject(error)
        }

        // 移除待处理列表中的请求
        error.config && removePendingRequest(error.config)

        if (error.response) {
            switch (error.response.status) {
                case 401:
                case 403:
                    // 清除用户信息
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    
                    // 如果不是登录页面，则跳转到登录页
                    if (router.currentRoute.value.path !== '/login') {
                        router.push({
                            path: '/login',
                            query: { redirect: router.currentRoute.value.fullPath }
                        })
                        ElMessage.error('登录已过期或无效，请重新登录')
                    }
                    break
                    
                case 404:
                    ElMessage.error('请求的资源不存在')
                    break
                    
                case 500:
                    ElMessage.error('服务器错误，请稍后重试')
                    break
                    
                default:
                    ElMessage.error(error.response.data?.message || '请求失败')
            }
        } else if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
            ElMessage.error('请求超时，请稍后重试')
        } else {
            ElMessage.error('网络错误，请稍后重试')
        }
        
        return Promise.reject(error)
    }
)

// 请求方法封装
export const request = {
    get(url, params, config = {}) {
        return instance.get(url, { params, ...config })
    },

    post(url, data, config = {}) {
        return instance.post(url, data, config)
    },

    put(url, data, config = {}) {
        return instance.put(url, data, config)
    },

    delete(url, params, config = {}) {
        return instance.delete(url, { params, ...config })
    },

    // 上传文件
    upload(url, file, config = {}) {
        const formData = new FormData()
        formData.append('file', file)
        return instance.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            ...config
        })
    },

    // 下载文件
    download(url, params, config = {}) {
        return instance.get(url, {
            params,
            responseType: 'blob',
            ...config
        })
    }
}

export default instance